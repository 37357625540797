import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ff9a844 = () => interopDefault(import('../pages/aarti/index.vue' /* webpackChunkName: "pages/aarti/index" */))
const _b8589bec = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _dafc3f34 = () => interopDefault(import('../pages/activities/index.vue' /* webpackChunkName: "pages/activities/index" */))
const _150dccd8 = () => interopDefault(import('../pages/add-place/index.vue' /* webpackChunkName: "pages/add-place/index" */))
const _384f5361 = () => interopDefault(import('../pages/advertising/index.vue' /* webpackChunkName: "pages/advertising/index" */))
const _2c233563 = () => interopDefault(import('../pages/affiliate-marketing/index.vue' /* webpackChunkName: "pages/affiliate-marketing/index" */))
const _1ca1737e = () => interopDefault(import('../pages/app-store-optimization/index.vue' /* webpackChunkName: "pages/app-store-optimization/index" */))
const _2aa2dcf3 = () => interopDefault(import('../pages/ashtavinayak/index.vue' /* webpackChunkName: "pages/ashtavinayak/index" */))
const _770fcc32 = () => interopDefault(import('../pages/best-casino-goa/index.vue' /* webpackChunkName: "pages/best-casino-goa/index" */))
const _2299e68e = () => interopDefault(import('../pages/best-casino-panjim/index.vue' /* webpackChunkName: "pages/best-casino-panjim/index" */))
const _3375900c = () => interopDefault(import('../pages/best-dinner-cruise-goa/index.vue' /* webpackChunkName: "pages/best-dinner-cruise-goa/index" */))
const _1e26f999 = () => interopDefault(import('../pages/best-photography-goa/index.vue' /* webpackChunkName: "pages/best-photography-goa/index" */))
const _9745fa90 = () => interopDefault(import('../pages/best-pre-wedding-shoot-goa/index.vue' /* webpackChunkName: "pages/best-pre-wedding-shoot-goa/index" */))
const _531b6c74 = () => interopDefault(import('../pages/bioluminescent-beach/index.vue' /* webpackChunkName: "pages/bioluminescent-beach/index" */))
const _45f6f74a = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _2300bd54 = () => interopDefault(import('../pages/boat-cruises-goa/index.vue' /* webpackChunkName: "pages/boat-cruises-goa/index" */))
const _74b66ca3 = () => interopDefault(import('../pages/caption/index.vue' /* webpackChunkName: "pages/caption/index" */))
const _f232fd74 = () => interopDefault(import('../pages/car-hire/index.vue' /* webpackChunkName: "pages/car-hire/index" */))
const _2b2ef1b6 = () => interopDefault(import('../pages/car-rental/index.vue' /* webpackChunkName: "pages/car-rental/index" */))
const _63c7621a = () => interopDefault(import('../pages/casino-goa/index.vue' /* webpackChunkName: "pages/casino-goa/index" */))
const _0c5418b4 = () => interopDefault(import('../pages/casino-panjim/index.vue' /* webpackChunkName: "pages/casino-panjim/index" */))
const _51974a55 = () => interopDefault(import('../pages/change-password/index.vue' /* webpackChunkName: "pages/change-password/index" */))
const _4892a5ff = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _6a86ca78 = () => interopDefault(import('../pages/client-feedback/index.vue' /* webpackChunkName: "pages/client-feedback/index" */))
const _782c1346 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _36a7370f = () => interopDefault(import('../pages/content-marketing/index.vue' /* webpackChunkName: "pages/content-marketing/index" */))
const _15d0e82e = () => interopDefault(import('../pages/cookies-policy/index.vue' /* webpackChunkName: "pages/cookies-policy/index" */))
const _2b96c6fc = () => interopDefault(import('../pages/create-event/index.vue' /* webpackChunkName: "pages/create-event/index" */))
const _9e862af4 = () => interopDefault(import('../pages/create-tour/index.vue' /* webpackChunkName: "pages/create-tour/index" */))
const _706d1cd3 = () => interopDefault(import('../pages/cruise-dinner-goa/index.vue' /* webpackChunkName: "pages/cruise-dinner-goa/index" */))
const _492f3fd3 = () => interopDefault(import('../pages/cruise-party-goa/index.vue' /* webpackChunkName: "pages/cruise-party-goa/index" */))
const _0493ae4b = () => interopDefault(import('../pages/cruise-ship-packages-goa/index.vue' /* webpackChunkName: "pages/cruise-ship-packages-goa/index" */))
const _12d2eb2e = () => interopDefault(import('../pages/digital-marketing/index.vue' /* webpackChunkName: "pages/digital-marketing/index" */))
const _17ab127e = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _16007d92 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _9d626af4 = () => interopDefault(import('../pages/events-today/index.vue' /* webpackChunkName: "pages/events-today/index" */))
const _8778db94 = () => interopDefault(import('../pages/events-tomorrow/index.vue' /* webpackChunkName: "pages/events-tomorrow/index" */))
const _28bb94a8 = () => interopDefault(import('../pages/events-weekend/index.vue' /* webpackChunkName: "pages/events-weekend/index" */))
const _8558cde0 = () => interopDefault(import('../pages/explore/index.vue' /* webpackChunkName: "pages/explore/index" */))
const _68f5c4c2 = () => interopDefault(import('../pages/family-photoshoot-goa/index.vue' /* webpackChunkName: "pages/family-photoshoot-goa/index" */))
const _32ab82d3 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _0dcd2ffc = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _1fd55f84 = () => interopDefault(import('../pages/games/index.vue' /* webpackChunkName: "pages/games/index" */))
const _14659341 = () => interopDefault(import('../pages/goa-best-photography/index.vue' /* webpackChunkName: "pages/goa-best-photography/index" */))
const _30f6396b = () => interopDefault(import('../pages/goa-boat-cruise/index.vue' /* webpackChunkName: "pages/goa-boat-cruise/index" */))
const _d02dcc94 = () => interopDefault(import('../pages/goa-casino-games/index.vue' /* webpackChunkName: "pages/goa-casino-games/index" */))
const _028f1b60 = () => interopDefault(import('../pages/goa-casino-online-booking/index.vue' /* webpackChunkName: "pages/goa-casino-online-booking/index" */))
const _66136db6 = () => interopDefault(import('../pages/goa-pre-wedding-shoot/index.vue' /* webpackChunkName: "pages/goa-pre-wedding-shoot/index" */))
const _48ee4643 = () => interopDefault(import('../pages/goa-river-cruise/index.vue' /* webpackChunkName: "pages/goa-river-cruise/index" */))
const _3c860ab7 = () => interopDefault(import('../pages/goataxihub/index.vue' /* webpackChunkName: "pages/goataxihub/index" */))
const _7de3963c = () => interopDefault(import('../pages/graphic-design/index.vue' /* webpackChunkName: "pages/graphic-design/index" */))
const _70d12d8c = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _3ce6cb90 = () => interopDefault(import('../pages/hotels/index.vue' /* webpackChunkName: "pages/hotels/index" */))
const _76f4eb94 = () => interopDefault(import('../pages/how-it-works/index.vue' /* webpackChunkName: "pages/how-it-works/index" */))
const _594be7a2 = () => interopDefault(import('../pages/influencer-marketing/index.vue' /* webpackChunkName: "pages/influencer-marketing/index" */))
const _0dc1e7c1 = () => interopDefault(import('../pages/instagram-captions/index.vue' /* webpackChunkName: "pages/instagram-captions/index" */))
const _aa874a34 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _57e31004 = () => interopDefault(import('../pages/mandovi-river-cruise/index.vue' /* webpackChunkName: "pages/mandovi-river-cruise/index" */))
const _4c3e3992 = () => interopDefault(import('../pages/maternity-photoshoot-goa/index.vue' /* webpackChunkName: "pages/maternity-photoshoot-goa/index" */))
const _30502095 = () => interopDefault(import('../pages/my-offers/index.vue' /* webpackChunkName: "pages/my-offers/index" */))
const _16c7d832 = () => interopDefault(import('../pages/my-rentals/index.vue' /* webpackChunkName: "pages/my-rentals/index" */))
const _1d9df133 = () => interopDefault(import('../pages/my-reviews/index.vue' /* webpackChunkName: "pages/my-reviews/index" */))
const _e020b426 = () => interopDefault(import('../pages/my-taxis/index.vue' /* webpackChunkName: "pages/my-taxis/index" */))
const _4696e4ff = () => interopDefault(import('../pages/my-tickets/index.vue' /* webpackChunkName: "pages/my-tickets/index" */))
const _45559430 = () => interopDefault(import('../pages/namavali/index.vue' /* webpackChunkName: "pages/namavali/index" */))
const _261a564c = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _fe6b31e2 = () => interopDefault(import('../pages/night-cruise-goa/index.vue' /* webpackChunkName: "pages/night-cruise-goa/index" */))
const _34aa35a6 = () => interopDefault(import('../pages/north-goa-sightseeing/index.vue' /* webpackChunkName: "pages/north-goa-sightseeing/index" */))
const _1482f134 = () => interopDefault(import('../pages/north-goa-sightseeing-package/index.vue' /* webpackChunkName: "pages/north-goa-sightseeing-package/index" */))
const _189d2bb2 = () => interopDefault(import('../pages/north-goa-sightseeing-package-by-bus/index.vue' /* webpackChunkName: "pages/north-goa-sightseeing-package-by-bus/index" */))
const _1fd423e4 = () => interopDefault(import('../pages/north-goa-sightseeing-tour/index.vue' /* webpackChunkName: "pages/north-goa-sightseeing-tour/index" */))
const _4b3c555c = () => interopDefault(import('../pages/north-goa-tourist-places/index.vue' /* webpackChunkName: "pages/north-goa-tourist-places/index" */))
const _90486ee0 = () => interopDefault(import('../pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _e3e2140e = () => interopDefault(import('../pages/offers-checkout/index.vue' /* webpackChunkName: "pages/offers-checkout/index" */))
const _7766ee34 = () => interopDefault(import('../pages/packages/index.vue' /* webpackChunkName: "pages/packages/index" */))
const _27a84d74 = () => interopDefault(import('../pages/photography-goa/index.vue' /* webpackChunkName: "pages/photography-goa/index" */))
const _0a31c105 = () => interopDefault(import('../pages/places/index.vue' /* webpackChunkName: "pages/places/index" */))
const _09b2003e = () => interopDefault(import('../pages/places-of-goa/index.vue' /* webpackChunkName: "pages/places-of-goa/index" */))
const _fcf75b56 = () => interopDefault(import('../pages/places-to-visit-in-north-goa/index.vue' /* webpackChunkName: "pages/places-to-visit-in-north-goa/index" */))
const _554fef7e = () => interopDefault(import('../pages/podcast/index.vue' /* webpackChunkName: "pages/podcast/index" */))
const _7599a11c = () => interopDefault(import('../pages/post-wedding-photography-goa/index.vue' /* webpackChunkName: "pages/post-wedding-photography-goa/index" */))
const _738ec7e0 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _5bc2ef06 = () => interopDefault(import('../pages/private-yacht-goa/index.vue' /* webpackChunkName: "pages/private-yacht-goa/index" */))
const _49c6fa98 = () => interopDefault(import('../pages/professional-photographer-goa/index.vue' /* webpackChunkName: "pages/professional-photographer-goa/index" */))
const _454ed070 = () => interopDefault(import('../pages/quotes/index.vue' /* webpackChunkName: "pages/quotes/index" */))
const _dd3be6bc = () => interopDefault(import('../pages/recipes/index.vue' /* webpackChunkName: "pages/recipes/index" */))
const _fab93b16 = () => interopDefault(import('../pages/refund-cancellation/index.vue' /* webpackChunkName: "pages/refund-cancellation/index" */))
const _67f991c8 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _266bdd4c = () => interopDefault(import('../pages/rental-checkout/index.vue' /* webpackChunkName: "pages/rental-checkout/index" */))
const _ea8c9552 = () => interopDefault(import('../pages/rental-detail/index.vue' /* webpackChunkName: "pages/rental-detail/index" */))
const _5e856b4c = () => interopDefault(import('../pages/rentals/index.vue' /* webpackChunkName: "pages/rentals/index" */))
const _419d77d2 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _9efebada = () => interopDefault(import('../pages/restaurants/index.vue' /* webpackChunkName: "pages/restaurants/index" */))
const _8344493e = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _cc63bf28 = () => interopDefault(import('../pages/search-engine-optimization/index.vue' /* webpackChunkName: "pages/search-engine-optimization/index" */))
const _afe171c0 = () => interopDefault(import('../pages/self-drive-cars/index.vue' /* webpackChunkName: "pages/self-drive-cars/index" */))
const _33459896 = () => interopDefault(import('../pages/social-media-marketing/index.vue' /* webpackChunkName: "pages/social-media-marketing/index" */))
const _6bb30816 = () => interopDefault(import('../pages/south-goa-sightseeing/index.vue' /* webpackChunkName: "pages/south-goa-sightseeing/index" */))
const _1dbad62e = () => interopDefault(import('../pages/south-goa-sightseeing-package/index.vue' /* webpackChunkName: "pages/south-goa-sightseeing-package/index" */))
const _48672b42 = () => interopDefault(import('../pages/south-goa-sightseeing-package-by-bus/index.vue' /* webpackChunkName: "pages/south-goa-sightseeing-package-by-bus/index" */))
const _269d5774 = () => interopDefault(import('../pages/south-goa-sightseeing-tour/index.vue' /* webpackChunkName: "pages/south-goa-sightseeing-tour/index" */))
const _4f005858 = () => interopDefault(import('../pages/st-francis-xavier-exposition/index.vue' /* webpackChunkName: "pages/st-francis-xavier-exposition/index" */))
const _c2cd13d2 = () => interopDefault(import('../pages/st-francis-xavier-goa/index.vue' /* webpackChunkName: "pages/st-francis-xavier-goa/index" */))
const _0909a1b1 = () => interopDefault(import('../pages/st-francis-xavier-goa-body-exposition/index.vue' /* webpackChunkName: "pages/st-francis-xavier-goa-body-exposition/index" */))
const _caf013a4 = () => interopDefault(import('../pages/stotra/index.vue' /* webpackChunkName: "pages/stotra/index" */))
const _23f8c052 = () => interopDefault(import('../pages/taxi/index.vue' /* webpackChunkName: "pages/taxi/index" */))
const _7bb142dc = () => interopDefault(import('../pages/taxi-checkout/index.vue' /* webpackChunkName: "pages/taxi-checkout/index" */))
const _27f5d377 = () => interopDefault(import('../pages/terms-conditions/index.vue' /* webpackChunkName: "pages/terms-conditions/index" */))
const _c1a73edc = () => interopDefault(import('../pages/top-casino-goa/index.vue' /* webpackChunkName: "pages/top-casino-goa/index" */))
const _a530a0b0 = () => interopDefault(import('../pages/tourism/index.vue' /* webpackChunkName: "pages/tourism/index" */))
const _9b0f53d0 = () => interopDefault(import('../pages/tours/index.vue' /* webpackChunkName: "pages/tours/index" */))
const _29e5df06 = () => interopDefault(import('../pages/website-development/index.vue' /* webpackChunkName: "pages/website-development/index" */))
const _5b8d0b18 = () => interopDefault(import('../pages/aarti/balaji/index.vue' /* webpackChunkName: "pages/aarti/balaji/index" */))
const _7427e738 = () => interopDefault(import('../pages/aarti/bhollya-shankara/index.vue' /* webpackChunkName: "pages/aarti/bhollya-shankara/index" */))
const _dd253f28 = () => interopDefault(import('../pages/aarti/dattatreya/index.vue' /* webpackChunkName: "pages/aarti/dattatreya/index" */))
const _424e1b60 = () => interopDefault(import('../pages/aarti/durga/index.vue' /* webpackChunkName: "pages/aarti/durga/index" */))
const _f54272ae = () => interopDefault(import('../pages/aarti/dyanraja/index.vue' /* webpackChunkName: "pages/aarti/dyanraja/index" */))
const _38b3f3c0 = () => interopDefault(import('../pages/aarti/gajanan-maharaj/index.vue' /* webpackChunkName: "pages/aarti/gajanan-maharaj/index" */))
const _68349aa7 = () => interopDefault(import('../pages/aarti/ganesh/index.vue' /* webpackChunkName: "pages/aarti/ganesh/index" */))
const _2545eb6f = () => interopDefault(import('../pages/aarti/ganpati/index.vue' /* webpackChunkName: "pages/aarti/ganpati/index" */))
const _6afa74a7 = () => interopDefault(import('../pages/aarti/ghalin-lotangan/index.vue' /* webpackChunkName: "pages/aarti/ghalin-lotangan/index" */))
const _1199bfc2 = () => interopDefault(import('../pages/aarti/krishna/index.vue' /* webpackChunkName: "pages/aarti/krishna/index" */))
const _3ba2f307 = () => interopDefault(import('../pages/aarti/mahalaxmi/index.vue' /* webpackChunkName: "pages/aarti/mahalaxmi/index" */))
const _4d9d5890 = () => interopDefault(import('../pages/aarti/maharudra/index.vue' /* webpackChunkName: "pages/aarti/maharudra/index" */))
const _35eacac2 = () => interopDefault(import('../pages/aarti/mangesh/index.vue' /* webpackChunkName: "pages/aarti/mangesh/index" */))
const _844496e6 = () => interopDefault(import('../pages/aarti/maruti/index.vue' /* webpackChunkName: "pages/aarti/maruti/index" */))
const _46da958d = () => interopDefault(import('../pages/aarti/morya/index.vue' /* webpackChunkName: "pages/aarti/morya/index" */))
const _385257a0 = () => interopDefault(import('../pages/aarti/namavali/index.vue' /* webpackChunkName: "pages/aarti/namavali/index" */))
const _57fcfc3e = () => interopDefault(import('../pages/aarti/pandhariraja/index.vue' /* webpackChunkName: "pages/aarti/pandhariraja/index" */))
const _29b2caba = () => interopDefault(import('../pages/aarti/pandhuranga/index.vue' /* webpackChunkName: "pages/aarti/pandhuranga/index" */))
const _3098cada = () => interopDefault(import('../pages/aarti/ram/index.vue' /* webpackChunkName: "pages/aarti/ram/index" */))
const _4fd919fc = () => interopDefault(import('../pages/aarti/sahitya/index.vue' /* webpackChunkName: "pages/aarti/sahitya/index" */))
const _bfa1fa64 = () => interopDefault(import('../pages/aarti/saibaba/index.vue' /* webpackChunkName: "pages/aarti/saibaba/index" */))
const _742c6a52 = () => interopDefault(import('../pages/aarti/satyanarayan/index.vue' /* webpackChunkName: "pages/aarti/satyanarayan/index" */))
const _7ee783c1 = () => interopDefault(import('../pages/aarti/shantadurga/index.vue' /* webpackChunkName: "pages/aarti/shantadurga/index" */))
const _898335d8 = () => interopDefault(import('../pages/aarti/shiva/index.vue' /* webpackChunkName: "pages/aarti/shiva/index" */))
const _5cf39d48 = () => interopDefault(import('../pages/aarti/vithoba/index.vue' /* webpackChunkName: "pages/aarti/vithoba/index" */))
const _5c1bf13a = () => interopDefault(import('../pages/aarti/yamunechya-tiri/index.vue' /* webpackChunkName: "pages/aarti/yamunechya-tiri/index" */))
const _e473548e = () => interopDefault(import('../pages/accounts/login/index.vue' /* webpackChunkName: "pages/accounts/login/index" */))
const _6371796c = () => interopDefault(import('../pages/ashtavinayak/ballaleshwar/index.vue' /* webpackChunkName: "pages/ashtavinayak/ballaleshwar/index" */))
const _6e9fa878 = () => interopDefault(import('../pages/ashtavinayak/chintamani/index.vue' /* webpackChunkName: "pages/ashtavinayak/chintamani/index" */))
const _25fb1738 = () => interopDefault(import('../pages/ashtavinayak/girijatmaj/index.vue' /* webpackChunkName: "pages/ashtavinayak/girijatmaj/index" */))
const _95738a9e = () => interopDefault(import('../pages/ashtavinayak/mahaganpati/index.vue' /* webpackChunkName: "pages/ashtavinayak/mahaganpati/index" */))
const _dbee86d8 = () => interopDefault(import('../pages/ashtavinayak/mayureshwar/index.vue' /* webpackChunkName: "pages/ashtavinayak/mayureshwar/index" */))
const _48694eaa = () => interopDefault(import('../pages/ashtavinayak/siddhivinayak/index.vue' /* webpackChunkName: "pages/ashtavinayak/siddhivinayak/index" */))
const _8183f0b6 = () => interopDefault(import('../pages/ashtavinayak/varadvinayak/index.vue' /* webpackChunkName: "pages/ashtavinayak/varadvinayak/index" */))
const _2e63fcd4 = () => interopDefault(import('../pages/ashtavinayak/vighnahar/index.vue' /* webpackChunkName: "pages/ashtavinayak/vighnahar/index" */))
const _d0c315c0 = () => interopDefault(import('../pages/goataxihub/cookie-policy.vue' /* webpackChunkName: "pages/goataxihub/cookie-policy" */))
const _461bd082 = () => interopDefault(import('../pages/goataxihub/privacy-policy.vue' /* webpackChunkName: "pages/goataxihub/privacy-policy" */))
const _4335e7e9 = () => interopDefault(import('../pages/goataxihub/terms-conditions.vue' /* webpackChunkName: "pages/goataxihub/terms-conditions" */))
const _c3d69618 = () => interopDefault(import('../pages/namavali/durga/index.vue' /* webpackChunkName: "pages/namavali/durga/index" */))
const _df1da6fa = () => interopDefault(import('../pages/namavali/ganesh/index.vue' /* webpackChunkName: "pages/namavali/ganesh/index" */))
const _4306e8f3 = () => interopDefault(import('../pages/namavali/hanuman/index.vue' /* webpackChunkName: "pages/namavali/hanuman/index" */))
const _52ee6c7a = () => interopDefault(import('../pages/namavali/krishna/index.vue' /* webpackChunkName: "pages/namavali/krishna/index" */))
const _7fadb120 = () => interopDefault(import('../pages/namavali/lakshmi/index.vue' /* webpackChunkName: "pages/namavali/lakshmi/index" */))
const _4b87b637 = () => interopDefault(import('../pages/namavali/ram/index.vue' /* webpackChunkName: "pages/namavali/ram/index" */))
const _3afae5ae = () => interopDefault(import('../pages/namavali/saraswati/index.vue' /* webpackChunkName: "pages/namavali/saraswati/index" */))
const _7a7a27b8 = () => interopDefault(import('../pages/namavali/shiva/index.vue' /* webpackChunkName: "pages/namavali/shiva/index" */))
const _5b5cb82e = () => interopDefault(import('../pages/namavali/swami-samarth/index.vue' /* webpackChunkName: "pages/namavali/swami-samarth/index" */))
const _34db5b2c = () => interopDefault(import('../pages/namavali/vishnu/index.vue' /* webpackChunkName: "pages/namavali/vishnu/index" */))
const _17937620 = () => interopDefault(import('../pages/quotes/author/index.vue' /* webpackChunkName: "pages/quotes/author/index" */))
const _68b3aebc = () => interopDefault(import('../pages/rental-checkout/checkout.vue' /* webpackChunkName: "pages/rental-checkout/checkout" */))
const _6a6ebe9e = () => interopDefault(import('../pages/rentals/best-car-rental-in-goa/index.vue' /* webpackChunkName: "pages/rentals/best-car-rental-in-goa/index" */))
const _6d5dd7df = () => interopDefault(import('../pages/rentals/car-goa-rent/index.vue' /* webpackChunkName: "pages/rentals/car-goa-rent/index" */))
const _307d2ca0 = () => interopDefault(import('../pages/rentals/car-hire-goa-airport/index.vue' /* webpackChunkName: "pages/rentals/car-hire-goa-airport/index" */))
const _84c58c18 = () => interopDefault(import('../pages/rentals/car-hire-goa-with-driver/index.vue' /* webpackChunkName: "pages/rentals/car-hire-goa-with-driver/index" */))
const _0c90b70e = () => interopDefault(import('../pages/rentals/car-hire-in-goa/index.vue' /* webpackChunkName: "pages/rentals/car-hire-in-goa/index" */))
const _7afcf0d0 = () => interopDefault(import('../pages/rentals/car-hire-in-goa-with-driver/index.vue' /* webpackChunkName: "pages/rentals/car-hire-in-goa-with-driver/index" */))
const _7b25f22d = () => interopDefault(import('../pages/rentals/car-rental-dabolim-airport/index.vue' /* webpackChunkName: "pages/rentals/car-rental-dabolim-airport/index" */))
const _728582bc = () => interopDefault(import('../pages/rentals/car-rental-goa/index.vue' /* webpackChunkName: "pages/rentals/car-rental-goa/index" */))
const _4baca220 = () => interopDefault(import('../pages/rentals/car-rental-goa-airport/index.vue' /* webpackChunkName: "pages/rentals/car-rental-goa-airport/index" */))
const _c5f34b40 = () => interopDefault(import('../pages/rentals/car-rental-in-goa-with-driver/index.vue' /* webpackChunkName: "pages/rentals/car-rental-in-goa-with-driver/index" */))
const _d8835af0 = () => interopDefault(import('../pages/rentals/car-rental-in-margao/index.vue' /* webpackChunkName: "pages/rentals/car-rental-in-margao/index" */))
const _2c2ee69c = () => interopDefault(import('../pages/rentals/car-rental-madgaon/index.vue' /* webpackChunkName: "pages/rentals/car-rental-madgaon/index" */))
const _1b006a1e = () => interopDefault(import('../pages/rentals/car-rental-panjim/index.vue' /* webpackChunkName: "pages/rentals/car-rental-panjim/index" */))
const _17295c22 = () => interopDefault(import('../pages/rentals/car-rental-south-goa/index.vue' /* webpackChunkName: "pages/rentals/car-rental-south-goa/index" */))
const _21153ec6 = () => interopDefault(import('../pages/rentals/goa-car-rental-price/index.vue' /* webpackChunkName: "pages/rentals/goa-car-rental-price/index" */))
const _7876c97c = () => interopDefault(import('../pages/rentals/goa-car-rental-self-drive/index.vue' /* webpackChunkName: "pages/rentals/goa-car-rental-self-drive/index" */))
const _3b199cb2 = () => interopDefault(import('../pages/rentals/goa-car-rental-service/index.vue' /* webpackChunkName: "pages/rentals/goa-car-rental-service/index" */))
const _22195cff = () => interopDefault(import('../pages/rentals/goa-car-rentals/index.vue' /* webpackChunkName: "pages/rentals/goa-car-rentals/index" */))
const _41dfef07 = () => interopDefault(import('../pages/rentals/goa-vehicle-rent/index.vue' /* webpackChunkName: "pages/rentals/goa-vehicle-rent/index" */))
const _df5de58c = () => interopDefault(import('../pages/rentals/self-drive-car-hire-goa/index.vue' /* webpackChunkName: "pages/rentals/self-drive-car-hire-goa/index" */))
const _27974e90 = () => interopDefault(import('../pages/rentals/self-drive-car-hire-goa-airport/index.vue' /* webpackChunkName: "pages/rentals/self-drive-car-hire-goa-airport/index" */))
const _1240280a = () => interopDefault(import('../pages/rentals/self-drive-cars-goa-airport/index.vue' /* webpackChunkName: "pages/rentals/self-drive-cars-goa-airport/index" */))
const _5f9d41ae = () => interopDefault(import('../pages/restaurants/index1.vue' /* webpackChunkName: "pages/restaurants/index1" */))
const _677e7525 = () => interopDefault(import('../pages/stotra/athrvashirsha/index.vue' /* webpackChunkName: "pages/stotra/athrvashirsha/index" */))
const _0c25d7fe = () => interopDefault(import('../pages/stotra/dattamaharaj/index.vue' /* webpackChunkName: "pages/stotra/dattamaharaj/index" */))
const _0e7fed57 = () => interopDefault(import('../pages/stotra/ganesh-chalisa/index.vue' /* webpackChunkName: "pages/stotra/ganesh-chalisa/index" */))
const _4dc4af59 = () => interopDefault(import('../pages/stotra/ganpati/index.vue' /* webpackChunkName: "pages/stotra/ganpati/index" */))
const _32f2c838 = () => interopDefault(import('../pages/stotra/gurucharitra/index.vue' /* webpackChunkName: "pages/stotra/gurucharitra/index" */))
const _065079da = () => interopDefault(import('../pages/stotra/hanuman-chalisa/index.vue' /* webpackChunkName: "pages/stotra/hanuman-chalisa/index" */))
const _15de0742 = () => interopDefault(import('../pages/stotra/krishnashtakam/index.vue' /* webpackChunkName: "pages/stotra/krishnashtakam/index" */))
const _96db210e = () => interopDefault(import('../pages/stotra/mahalakshmi/index.vue' /* webpackChunkName: "pages/stotra/mahalakshmi/index" */))
const _d43c693a = () => interopDefault(import('../pages/stotra/maruti/index.vue' /* webpackChunkName: "pages/stotra/maruti/index" */))
const _dc62f9ec = () => interopDefault(import('../pages/stotra/pranamyashirsha/index.vue' /* webpackChunkName: "pages/stotra/pranamyashirsha/index" */))
const _51e4bd0d = () => interopDefault(import('../pages/stotra/ramraksha/index.vue' /* webpackChunkName: "pages/stotra/ramraksha/index" */))
const _6b8e37b5 = () => interopDefault(import('../pages/stotra/shiv-stuti/index.vue' /* webpackChunkName: "pages/stotra/shiv-stuti/index" */))
const _1671a240 = () => interopDefault(import('../pages/stotra/shubhang-karoti/index.vue' /* webpackChunkName: "pages/stotra/shubhang-karoti/index" */))
const _d486289a = () => interopDefault(import('../pages/stotra/swami-ashtak/index.vue' /* webpackChunkName: "pages/stotra/swami-ashtak/index" */))
const _e706bfc4 = () => interopDefault(import('../pages/stotra/tarak-mantra/index.vue' /* webpackChunkName: "pages/stotra/tarak-mantra/index" */))
const _3fe2d890 = () => interopDefault(import('../pages/stotra/vishnushatanama/index.vue' /* webpackChunkName: "pages/stotra/vishnushatanama/index" */))
const _27f48a3a = () => interopDefault(import('../pages/stotra/vyankatesh/index.vue' /* webpackChunkName: "pages/stotra/vyankatesh/index" */))
const _38addbb0 = () => interopDefault(import('../pages/taxi/airport-cabs-goa.vue' /* webpackChunkName: "pages/taxi/airport-cabs-goa" */))
const _3222c4f7 = () => interopDefault(import('../pages/taxi/airport-taxi-booking.vue' /* webpackChunkName: "pages/taxi/airport-taxi-booking" */))
const _28437e0c = () => interopDefault(import('../pages/taxi/best-cab-service-goa.vue' /* webpackChunkName: "pages/taxi/best-cab-service-goa" */))
const _19f32980 = () => interopDefault(import('../pages/taxi/best-taxi-service-goa.vue' /* webpackChunkName: "pages/taxi/best-taxi-service-goa" */))
const _fa7b56c6 = () => interopDefault(import('../pages/taxi/cab-service-goa.vue' /* webpackChunkName: "pages/taxi/cab-service-goa" */))
const _0413909b = () => interopDefault(import('../pages/taxi/cab-service-goa-airport.vue' /* webpackChunkName: "pages/taxi/cab-service-goa-airport" */))
const _5b8e2b9d = () => interopDefault(import('../pages/taxi/cab-service-south-goa.vue' /* webpackChunkName: "pages/taxi/cab-service-south-goa" */))
const _7af0ad9b = () => interopDefault(import('../pages/taxi/cab-to-goa.vue' /* webpackChunkName: "pages/taxi/cab-to-goa" */))
const _4b486e64 = () => interopDefault(import('../pages/taxi/cabs-goa-airport.vue' /* webpackChunkName: "pages/taxi/cabs-goa-airport" */))
const _ef63fe18 = () => interopDefault(import('../pages/taxi/cabs-in-goa.vue' /* webpackChunkName: "pages/taxi/cabs-in-goa" */))
const _8a31ff26 = () => interopDefault(import('../pages/taxi/cheapest-cab-service-goa.vue' /* webpackChunkName: "pages/taxi/cheapest-cab-service-goa" */))
const _2c02586d = () => interopDefault(import('../pages/taxi/cheapest-taxi-service-goa.vue' /* webpackChunkName: "pages/taxi/cheapest-taxi-service-goa" */))
const _3ff7f992 = () => interopDefault(import('../pages/taxi/dabolim-airport-taxi.vue' /* webpackChunkName: "pages/taxi/dabolim-airport-taxi" */))
const _b205b082 = () => interopDefault(import('../pages/taxi/goa-airport-taxi.vue' /* webpackChunkName: "pages/taxi/goa-airport-taxi" */))
const _1383568e = () => interopDefault(import('../pages/taxi/goa-cabs.vue' /* webpackChunkName: "pages/taxi/goa-cabs" */))
const _1e7503fd = () => interopDefault(import('../pages/taxi/goa-taxi.vue' /* webpackChunkName: "pages/taxi/goa-taxi" */))
const _74cb1489 = () => interopDefault(import('../pages/taxi/goa-taxi-booking.vue' /* webpackChunkName: "pages/taxi/goa-taxi-booking" */))
const _dd14a6b8 = () => interopDefault(import('../pages/taxi/goa-taxi-rental.vue' /* webpackChunkName: "pages/taxi/goa-taxi-rental" */))
const _6aee6d53 = () => interopDefault(import('../pages/taxi/taxi-goa-airport.vue' /* webpackChunkName: "pages/taxi/taxi-goa-airport" */))
const _9b9baeb6 = () => interopDefault(import('../pages/taxi/taxi-in-goa.vue' /* webpackChunkName: "pages/taxi/taxi-in-goa" */))
const _6bde737c = () => interopDefault(import('../pages/taxi/taxi-services-in-goa.vue' /* webpackChunkName: "pages/taxi/taxi-services-in-goa" */))
const _627892e2 = () => interopDefault(import('../pages/watersports/5-in-1-water-sports-in-goa/index.vue' /* webpackChunkName: "pages/watersports/5-in-1-water-sports-in-goa/index" */))
const _7358ae54 = () => interopDefault(import('../pages/watersports/goa-water-sports-package/index.vue' /* webpackChunkName: "pages/watersports/goa-water-sports-package/index" */))
const _5fcedf7f = () => interopDefault(import('../pages/watersports/water-activities-in-goa/index.vue' /* webpackChunkName: "pages/watersports/water-activities-in-goa/index" */))
const _68b2d37a = () => interopDefault(import('../pages/watersports/water-activity-goa/index.vue' /* webpackChunkName: "pages/watersports/water-activity-goa/index" */))
const _dbc1f22a = () => interopDefault(import('../pages/watersports/water-rides-in-goa/index.vue' /* webpackChunkName: "pages/watersports/water-rides-in-goa/index" */))
const _5207d7fd = () => interopDefault(import('../pages/watersports/water-sports-5-in-1/index.vue' /* webpackChunkName: "pages/watersports/water-sports-5-in-1/index" */))
const _369f0df3 = () => interopDefault(import('../pages/watersports/water-sports-goa/index.vue' /* webpackChunkName: "pages/watersports/water-sports-goa/index" */))
const _657c9bad = () => interopDefault(import('../pages/watersports/water-sports-in-goa/index.vue' /* webpackChunkName: "pages/watersports/water-sports-in-goa/index" */))
const _5dc20225 = () => interopDefault(import('../pages/watersports/water-sports-in-north-goa/index.vue' /* webpackChunkName: "pages/watersports/water-sports-in-north-goa/index" */))
const _541384ed = () => interopDefault(import('../pages/watersports/water-sports-in-south-goa/index.vue' /* webpackChunkName: "pages/watersports/water-sports-in-south-goa/index" */))
const _69879001 = () => interopDefault(import('../pages/aarti/namavali/namavali.vue' /* webpackChunkName: "pages/aarti/namavali/namavali" */))
const _25e80afd = () => interopDefault(import('../pages/aarti/sahitya/sahitya.vue' /* webpackChunkName: "pages/aarti/sahitya/sahitya" */))
const _70a7fd16 = () => interopDefault(import('../pages/places/category/_slug.vue' /* webpackChunkName: "pages/places/category/_slug" */))
const _0f42fb48 = () => interopDefault(import('../pages/restaurants/category/_slug.vue' /* webpackChunkName: "pages/restaurants/category/_slug" */))
const _de6981c4 = () => interopDefault(import('../pages/activities/_slug.vue' /* webpackChunkName: "pages/activities/_slug" */))
const _496439da = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _72ffcb5b = () => interopDefault(import('../pages/caption/_slug.vue' /* webpackChunkName: "pages/caption/_slug" */))
const _5e83d1f6 = () => interopDefault(import('../pages/edit-event/_index.vue' /* webpackChunkName: "pages/edit-event/_index" */))
const _f4884f4c = () => interopDefault(import('../pages/enquiry-sent/_index.vue' /* webpackChunkName: "pages/enquiry-sent/_index" */))
const _27e4b38f = () => interopDefault(import('../pages/event/_slug.vue' /* webpackChunkName: "pages/event/_slug" */))
const _1449dc4a = () => interopDefault(import('../pages/events/_slug.vue' /* webpackChunkName: "pages/events/_slug" */))
const _0be5a051 = () => interopDefault(import('../pages/experiences/_category.vue' /* webpackChunkName: "pages/experiences/_category" */))
const _431643ca = () => interopDefault(import('../pages/explore/_category.vue' /* webpackChunkName: "pages/explore/_category" */))
const _15af6a29 = () => interopDefault(import('../pages/hotel/_slug.vue' /* webpackChunkName: "pages/hotel/_slug" */))
const _0c0b4679 = () => interopDefault(import('../pages/instagram-captions/_slug.vue' /* webpackChunkName: "pages/instagram-captions/_slug" */))
const _3840bf8f = () => interopDefault(import('../pages/offers-success/_index.vue' /* webpackChunkName: "pages/offers-success/_index" */))
const _93b5b170 = () => interopDefault(import('../pages/offers/_slug.vue' /* webpackChunkName: "pages/offers/_slug" */))
const _4d260b56 = () => interopDefault(import('../pages/order-details/_index.vue' /* webpackChunkName: "pages/order-details/_index" */))
const _7ad430c4 = () => interopDefault(import('../pages/packages/_slug.vue' /* webpackChunkName: "pages/packages/_slug" */))
const _087b1fbd = () => interopDefault(import('../pages/places/_slug.vue' /* webpackChunkName: "pages/places/_slug" */))
const _58bd320e = () => interopDefault(import('../pages/podcast/_slug.vue' /* webpackChunkName: "pages/podcast/_slug" */))
const _43982f28 = () => interopDefault(import('../pages/quotes/_slug.vue' /* webpackChunkName: "pages/quotes/_slug" */))
const _3ce5fb02 = () => interopDefault(import('../pages/recipe/_slug.vue' /* webpackChunkName: "pages/recipe/_slug" */))
const _e0a9294c = () => interopDefault(import('../pages/recipes/_slug.vue' /* webpackChunkName: "pages/recipes/_slug" */))
const _defe783c = () => interopDefault(import('../pages/rental-order-details/_index.vue' /* webpackChunkName: "pages/rental-order-details/_index" */))
const _5ec4b23c = () => interopDefault(import('../pages/rental-success/_index.vue' /* webpackChunkName: "pages/rental-success/_index" */))
const _cf892b24 = () => interopDefault(import('../pages/restaurant/_slug.vue' /* webpackChunkName: "pages/restaurant/_slug" */))
const _1a7720d6 = () => interopDefault(import('../pages/success/_index.vue' /* webpackChunkName: "pages/success/_index" */))
const _d437577a = () => interopDefault(import('../pages/tags/_index.vue' /* webpackChunkName: "pages/tags/_index" */))
const _a2688eb0 = () => interopDefault(import('../pages/taxi-order-details/_index.vue' /* webpackChunkName: "pages/taxi-order-details/_index" */))
const _6c592828 = () => interopDefault(import('../pages/taxi-success/_index.vue' /* webpackChunkName: "pages/taxi-success/_index" */))
const _a89de340 = () => interopDefault(import('../pages/tourism/_slug.vue' /* webpackChunkName: "pages/tourism/_slug" */))
const _9e7c9660 = () => interopDefault(import('../pages/tours/_slug.vue' /* webpackChunkName: "pages/tours/_slug" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8c4e7e6a = () => interopDefault(import('../pages/_state/index.vue' /* webpackChunkName: "pages/_state/index" */))
const _ebf5ac72 = () => interopDefault(import('../pages/_state/activities/index.vue' /* webpackChunkName: "pages/_state/activities/index" */))
const _4f64f6e6 = () => interopDefault(import('../pages/_state/beaches/index.vue' /* webpackChunkName: "pages/_state/beaches/index" */))
const _cb1fa5f6 = () => interopDefault(import('../pages/_state/city/index.vue' /* webpackChunkName: "pages/_state/city/index" */))
const _24999f73 = () => interopDefault(import('../pages/_state/events/index.vue' /* webpackChunkName: "pages/_state/events/index" */))
const _55b580b2 = () => interopDefault(import('../pages/_state/events-today/index.vue' /* webpackChunkName: "pages/_state/events-today/index" */))
const _35ebfd75 = () => interopDefault(import('../pages/_state/events-tomorrow/index.vue' /* webpackChunkName: "pages/_state/events-tomorrow/index" */))
const _73afe68d = () => interopDefault(import('../pages/_state/events-weekend/index.vue' /* webpackChunkName: "pages/_state/events-weekend/index" */))
const _f5de610e = () => interopDefault(import('../pages/_state/farms/index.vue' /* webpackChunkName: "pages/_state/farms/index" */))
const _150d5bc4 = () => interopDefault(import('../pages/_state/forts/index.vue' /* webpackChunkName: "pages/_state/forts/index" */))
const _4775b180 = () => interopDefault(import('../pages/_state/hostels/index.vue' /* webpackChunkName: "pages/_state/hostels/index" */))
const _1fb487ce = () => interopDefault(import('../pages/_state/hotels/index.vue' /* webpackChunkName: "pages/_state/hotels/index" */))
const _0cf23e50 = () => interopDefault(import('../pages/_state/nightlife/index.vue' /* webpackChunkName: "pages/_state/nightlife/index" */))
const _ddb692f2 = () => interopDefault(import('../pages/_state/packages/index.vue' /* webpackChunkName: "pages/_state/packages/index" */))
const _ed1f7588 = () => interopDefault(import('../pages/_state/picnic-spots/index.vue' /* webpackChunkName: "pages/_state/picnic-spots/index" */))
const _18cae2e6 = () => interopDefault(import('../pages/_state/places/index.vue' /* webpackChunkName: "pages/_state/places/index" */))
const _7e2c9978 = () => interopDefault(import('../pages/_state/religious-places/index.vue' /* webpackChunkName: "pages/_state/religious-places/index" */))
const _ad32f55c = () => interopDefault(import('../pages/_state/restaurants/index.vue' /* webpackChunkName: "pages/_state/restaurants/index" */))
const _0c49ab3e = () => interopDefault(import('../pages/_state/villages/index.vue' /* webpackChunkName: "pages/_state/villages/index" */))
const _3e06467b = () => interopDefault(import('../pages/_state/waterfalls/index.vue' /* webpackChunkName: "pages/_state/waterfalls/index" */))
const _1b8a89c8 = () => interopDefault(import('../pages/_state/packages/category/index.vue' /* webpackChunkName: "pages/_state/packages/category/index" */))
const _03d14e94 = () => interopDefault(import('../pages/_state/activities/category/_slug.vue' /* webpackChunkName: "pages/_state/activities/category/_slug" */))
const _1ef7cc58 = () => interopDefault(import('../pages/_state/packages/category/_slug.vue' /* webpackChunkName: "pages/_state/packages/category/_slug" */))
const _60a6bf69 = () => interopDefault(import('../pages/_state/restaurants/category/_slug.vue' /* webpackChunkName: "pages/_state/restaurants/category/_slug" */))
const _836c9afc = () => interopDefault(import('../pages/_state/activities/_city/index.vue' /* webpackChunkName: "pages/_state/activities/_city/index" */))
const _14e8cf40 = () => interopDefault(import('../pages/_state/city/_city/index.vue' /* webpackChunkName: "pages/_state/city/_city/index" */))
const _e68a0a04 = () => interopDefault(import('../pages/_state/events/_category.vue' /* webpackChunkName: "pages/_state/events/_category" */))
const _4bf3bf54 = () => interopDefault(import('../pages/_state/hotels/_city/index.vue' /* webpackChunkName: "pages/_state/hotels/_city/index" */))
const _2e01d861 = () => interopDefault(import('../pages/_state/places/_city/index.vue' /* webpackChunkName: "pages/_state/places/_city/index" */))
const _be060c66 = () => interopDefault(import('../pages/_state/restaurants/_city/index.vue' /* webpackChunkName: "pages/_state/restaurants/_city/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aarti",
    component: _1ff9a844,
    name: "aarti"
  }, {
    path: "/about",
    component: _b8589bec,
    name: "about"
  }, {
    path: "/activities",
    component: _dafc3f34,
    name: "activities"
  }, {
    path: "/add-place",
    component: _150dccd8,
    name: "add-place"
  }, {
    path: "/advertising",
    component: _384f5361,
    name: "advertising"
  }, {
    path: "/affiliate-marketing",
    component: _2c233563,
    name: "affiliate-marketing"
  }, {
    path: "/app-store-optimization",
    component: _1ca1737e,
    name: "app-store-optimization"
  }, {
    path: "/ashtavinayak",
    component: _2aa2dcf3,
    name: "ashtavinayak"
  }, {
    path: "/best-casino-goa",
    component: _770fcc32,
    name: "best-casino-goa"
  }, {
    path: "/best-casino-panjim",
    component: _2299e68e,
    name: "best-casino-panjim"
  }, {
    path: "/best-dinner-cruise-goa",
    component: _3375900c,
    name: "best-dinner-cruise-goa"
  }, {
    path: "/best-photography-goa",
    component: _1e26f999,
    name: "best-photography-goa"
  }, {
    path: "/best-pre-wedding-shoot-goa",
    component: _9745fa90,
    name: "best-pre-wedding-shoot-goa"
  }, {
    path: "/bioluminescent-beach",
    component: _531b6c74,
    name: "bioluminescent-beach"
  }, {
    path: "/blog",
    component: _45f6f74a,
    name: "blog"
  }, {
    path: "/boat-cruises-goa",
    component: _2300bd54,
    name: "boat-cruises-goa"
  }, {
    path: "/caption",
    component: _74b66ca3,
    name: "caption"
  }, {
    path: "/car-hire",
    component: _f232fd74,
    name: "car-hire"
  }, {
    path: "/car-rental",
    component: _2b2ef1b6,
    name: "car-rental"
  }, {
    path: "/casino-goa",
    component: _63c7621a,
    name: "casino-goa"
  }, {
    path: "/casino-panjim",
    component: _0c5418b4,
    name: "casino-panjim"
  }, {
    path: "/change-password",
    component: _51974a55,
    name: "change-password"
  }, {
    path: "/checkout",
    component: _4892a5ff,
    name: "checkout"
  }, {
    path: "/client-feedback",
    component: _6a86ca78,
    name: "client-feedback"
  }, {
    path: "/contact",
    component: _782c1346,
    name: "contact"
  }, {
    path: "/content-marketing",
    component: _36a7370f,
    name: "content-marketing"
  }, {
    path: "/cookies-policy",
    component: _15d0e82e,
    name: "cookies-policy"
  }, {
    path: "/create-event",
    component: _2b96c6fc,
    name: "create-event"
  }, {
    path: "/create-tour",
    component: _9e862af4,
    name: "create-tour"
  }, {
    path: "/cruise-dinner-goa",
    component: _706d1cd3,
    name: "cruise-dinner-goa"
  }, {
    path: "/cruise-party-goa",
    component: _492f3fd3,
    name: "cruise-party-goa"
  }, {
    path: "/cruise-ship-packages-goa",
    component: _0493ae4b,
    name: "cruise-ship-packages-goa"
  }, {
    path: "/digital-marketing",
    component: _12d2eb2e,
    name: "digital-marketing"
  }, {
    path: "/download",
    component: _17ab127e,
    name: "download"
  }, {
    path: "/events",
    component: _16007d92,
    name: "events"
  }, {
    path: "/events-today",
    component: _9d626af4,
    name: "events-today"
  }, {
    path: "/events-tomorrow",
    component: _8778db94,
    name: "events-tomorrow"
  }, {
    path: "/events-weekend",
    component: _28bb94a8,
    name: "events-weekend"
  }, {
    path: "/explore",
    component: _8558cde0,
    name: "explore"
  }, {
    path: "/family-photoshoot-goa",
    component: _68f5c4c2,
    name: "family-photoshoot-goa"
  }, {
    path: "/faq",
    component: _32ab82d3,
    name: "faq"
  }, {
    path: "/forgot-password",
    component: _0dcd2ffc,
    name: "forgot-password"
  }, {
    path: "/games",
    component: _1fd55f84,
    name: "games"
  }, {
    path: "/goa-best-photography",
    component: _14659341,
    name: "goa-best-photography"
  }, {
    path: "/goa-boat-cruise",
    component: _30f6396b,
    name: "goa-boat-cruise"
  }, {
    path: "/goa-casino-games",
    component: _d02dcc94,
    name: "goa-casino-games"
  }, {
    path: "/goa-casino-online-booking",
    component: _028f1b60,
    name: "goa-casino-online-booking"
  }, {
    path: "/goa-pre-wedding-shoot",
    component: _66136db6,
    name: "goa-pre-wedding-shoot"
  }, {
    path: "/goa-river-cruise",
    component: _48ee4643,
    name: "goa-river-cruise"
  }, {
    path: "/goataxihub",
    component: _3c860ab7,
    name: "goataxihub"
  }, {
    path: "/graphic-design",
    component: _7de3963c,
    name: "graphic-design"
  }, {
    path: "/help",
    component: _70d12d8c,
    name: "help"
  }, {
    path: "/hotels",
    component: _3ce6cb90,
    name: "hotels"
  }, {
    path: "/how-it-works",
    component: _76f4eb94,
    name: "how-it-works"
  }, {
    path: "/influencer-marketing",
    component: _594be7a2,
    name: "influencer-marketing"
  }, {
    path: "/instagram-captions",
    component: _0dc1e7c1,
    name: "instagram-captions"
  }, {
    path: "/login",
    component: _aa874a34,
    name: "login"
  }, {
    path: "/mandovi-river-cruise",
    component: _57e31004,
    name: "mandovi-river-cruise"
  }, {
    path: "/maternity-photoshoot-goa",
    component: _4c3e3992,
    name: "maternity-photoshoot-goa"
  }, {
    path: "/my-offers",
    component: _30502095,
    name: "my-offers"
  }, {
    path: "/my-rentals",
    component: _16c7d832,
    name: "my-rentals"
  }, {
    path: "/my-reviews",
    component: _1d9df133,
    name: "my-reviews"
  }, {
    path: "/my-taxis",
    component: _e020b426,
    name: "my-taxis"
  }, {
    path: "/my-tickets",
    component: _4696e4ff,
    name: "my-tickets"
  }, {
    path: "/namavali",
    component: _45559430,
    name: "namavali"
  }, {
    path: "/news",
    component: _261a564c,
    name: "news"
  }, {
    path: "/night-cruise-goa",
    component: _fe6b31e2,
    name: "night-cruise-goa"
  }, {
    path: "/north-goa-sightseeing",
    component: _34aa35a6,
    name: "north-goa-sightseeing"
  }, {
    path: "/north-goa-sightseeing-package",
    component: _1482f134,
    name: "north-goa-sightseeing-package"
  }, {
    path: "/north-goa-sightseeing-package-by-bus",
    component: _189d2bb2,
    name: "north-goa-sightseeing-package-by-bus"
  }, {
    path: "/north-goa-sightseeing-tour",
    component: _1fd423e4,
    name: "north-goa-sightseeing-tour"
  }, {
    path: "/north-goa-tourist-places",
    component: _4b3c555c,
    name: "north-goa-tourist-places"
  }, {
    path: "/offers",
    component: _90486ee0,
    name: "offers"
  }, {
    path: "/offers-checkout",
    component: _e3e2140e,
    name: "offers-checkout"
  }, {
    path: "/packages",
    component: _7766ee34,
    name: "packages"
  }, {
    path: "/photography-goa",
    component: _27a84d74,
    name: "photography-goa"
  }, {
    path: "/places",
    component: _0a31c105,
    name: "places"
  }, {
    path: "/places-of-goa",
    component: _09b2003e,
    name: "places-of-goa"
  }, {
    path: "/places-to-visit-in-north-goa",
    component: _fcf75b56,
    name: "places-to-visit-in-north-goa"
  }, {
    path: "/podcast",
    component: _554fef7e,
    name: "podcast"
  }, {
    path: "/post-wedding-photography-goa",
    component: _7599a11c,
    name: "post-wedding-photography-goa"
  }, {
    path: "/privacy-policy",
    component: _738ec7e0,
    name: "privacy-policy"
  }, {
    path: "/private-yacht-goa",
    component: _5bc2ef06,
    name: "private-yacht-goa"
  }, {
    path: "/professional-photographer-goa",
    component: _49c6fa98,
    name: "professional-photographer-goa"
  }, {
    path: "/quotes",
    component: _454ed070,
    name: "quotes"
  }, {
    path: "/recipes",
    component: _dd3be6bc,
    name: "recipes"
  }, {
    path: "/refund-cancellation",
    component: _fab93b16,
    name: "refund-cancellation"
  }, {
    path: "/register",
    component: _67f991c8,
    name: "register"
  }, {
    path: "/rental-checkout",
    component: _266bdd4c,
    name: "rental-checkout"
  }, {
    path: "/rental-detail",
    component: _ea8c9552,
    name: "rental-detail"
  }, {
    path: "/rentals",
    component: _5e856b4c,
    name: "rentals"
  }, {
    path: "/reset-password",
    component: _419d77d2,
    name: "reset-password"
  }, {
    path: "/restaurants",
    component: _9efebada,
    name: "restaurants"
  }, {
    path: "/search",
    component: _8344493e,
    name: "search"
  }, {
    path: "/search-engine-optimization",
    component: _cc63bf28,
    name: "search-engine-optimization"
  }, {
    path: "/self-drive-cars",
    component: _afe171c0,
    name: "self-drive-cars"
  }, {
    path: "/social-media-marketing",
    component: _33459896,
    name: "social-media-marketing"
  }, {
    path: "/south-goa-sightseeing",
    component: _6bb30816,
    name: "south-goa-sightseeing"
  }, {
    path: "/south-goa-sightseeing-package",
    component: _1dbad62e,
    name: "south-goa-sightseeing-package"
  }, {
    path: "/south-goa-sightseeing-package-by-bus",
    component: _48672b42,
    name: "south-goa-sightseeing-package-by-bus"
  }, {
    path: "/south-goa-sightseeing-tour",
    component: _269d5774,
    name: "south-goa-sightseeing-tour"
  }, {
    path: "/st-francis-xavier-exposition",
    component: _4f005858,
    name: "st-francis-xavier-exposition"
  }, {
    path: "/st-francis-xavier-goa",
    component: _c2cd13d2,
    name: "st-francis-xavier-goa"
  }, {
    path: "/st-francis-xavier-goa-body-exposition",
    component: _0909a1b1,
    name: "st-francis-xavier-goa-body-exposition"
  }, {
    path: "/stotra",
    component: _caf013a4,
    name: "stotra"
  }, {
    path: "/taxi",
    component: _23f8c052,
    name: "taxi"
  }, {
    path: "/taxi-checkout",
    component: _7bb142dc,
    name: "taxi-checkout"
  }, {
    path: "/terms-conditions",
    component: _27f5d377,
    name: "terms-conditions"
  }, {
    path: "/top-casino-goa",
    component: _c1a73edc,
    name: "top-casino-goa"
  }, {
    path: "/tourism",
    component: _a530a0b0,
    name: "tourism"
  }, {
    path: "/tours",
    component: _9b0f53d0,
    name: "tours"
  }, {
    path: "/website-development",
    component: _29e5df06,
    name: "website-development"
  }, {
    path: "/aarti/balaji",
    component: _5b8d0b18,
    name: "aarti-balaji"
  }, {
    path: "/aarti/bhollya-shankara",
    component: _7427e738,
    name: "aarti-bhollya-shankara"
  }, {
    path: "/aarti/dattatreya",
    component: _dd253f28,
    name: "aarti-dattatreya"
  }, {
    path: "/aarti/durga",
    component: _424e1b60,
    name: "aarti-durga"
  }, {
    path: "/aarti/dyanraja",
    component: _f54272ae,
    name: "aarti-dyanraja"
  }, {
    path: "/aarti/gajanan-maharaj",
    component: _38b3f3c0,
    name: "aarti-gajanan-maharaj"
  }, {
    path: "/aarti/ganesh",
    component: _68349aa7,
    name: "aarti-ganesh"
  }, {
    path: "/aarti/ganpati",
    component: _2545eb6f,
    name: "aarti-ganpati"
  }, {
    path: "/aarti/ghalin-lotangan",
    component: _6afa74a7,
    name: "aarti-ghalin-lotangan"
  }, {
    path: "/aarti/krishna",
    component: _1199bfc2,
    name: "aarti-krishna"
  }, {
    path: "/aarti/mahalaxmi",
    component: _3ba2f307,
    name: "aarti-mahalaxmi"
  }, {
    path: "/aarti/maharudra",
    component: _4d9d5890,
    name: "aarti-maharudra"
  }, {
    path: "/aarti/mangesh",
    component: _35eacac2,
    name: "aarti-mangesh"
  }, {
    path: "/aarti/maruti",
    component: _844496e6,
    name: "aarti-maruti"
  }, {
    path: "/aarti/morya",
    component: _46da958d,
    name: "aarti-morya"
  }, {
    path: "/aarti/namavali",
    component: _385257a0,
    name: "aarti-namavali"
  }, {
    path: "/aarti/pandhariraja",
    component: _57fcfc3e,
    name: "aarti-pandhariraja"
  }, {
    path: "/aarti/pandhuranga",
    component: _29b2caba,
    name: "aarti-pandhuranga"
  }, {
    path: "/aarti/ram",
    component: _3098cada,
    name: "aarti-ram"
  }, {
    path: "/aarti/sahitya",
    component: _4fd919fc,
    name: "aarti-sahitya"
  }, {
    path: "/aarti/saibaba",
    component: _bfa1fa64,
    name: "aarti-saibaba"
  }, {
    path: "/aarti/satyanarayan",
    component: _742c6a52,
    name: "aarti-satyanarayan"
  }, {
    path: "/aarti/shantadurga",
    component: _7ee783c1,
    name: "aarti-shantadurga"
  }, {
    path: "/aarti/shiva",
    component: _898335d8,
    name: "aarti-shiva"
  }, {
    path: "/aarti/vithoba",
    component: _5cf39d48,
    name: "aarti-vithoba"
  }, {
    path: "/aarti/yamunechya-tiri",
    component: _5c1bf13a,
    name: "aarti-yamunechya-tiri"
  }, {
    path: "/accounts/login",
    component: _e473548e,
    name: "accounts-login"
  }, {
    path: "/ashtavinayak/ballaleshwar",
    component: _6371796c,
    name: "ashtavinayak-ballaleshwar"
  }, {
    path: "/ashtavinayak/chintamani",
    component: _6e9fa878,
    name: "ashtavinayak-chintamani"
  }, {
    path: "/ashtavinayak/girijatmaj",
    component: _25fb1738,
    name: "ashtavinayak-girijatmaj"
  }, {
    path: "/ashtavinayak/mahaganpati",
    component: _95738a9e,
    name: "ashtavinayak-mahaganpati"
  }, {
    path: "/ashtavinayak/mayureshwar",
    component: _dbee86d8,
    name: "ashtavinayak-mayureshwar"
  }, {
    path: "/ashtavinayak/siddhivinayak",
    component: _48694eaa,
    name: "ashtavinayak-siddhivinayak"
  }, {
    path: "/ashtavinayak/varadvinayak",
    component: _8183f0b6,
    name: "ashtavinayak-varadvinayak"
  }, {
    path: "/ashtavinayak/vighnahar",
    component: _2e63fcd4,
    name: "ashtavinayak-vighnahar"
  }, {
    path: "/goataxihub/cookie-policy",
    component: _d0c315c0,
    name: "goataxihub-cookie-policy"
  }, {
    path: "/goataxihub/privacy-policy",
    component: _461bd082,
    name: "goataxihub-privacy-policy"
  }, {
    path: "/goataxihub/terms-conditions",
    component: _4335e7e9,
    name: "goataxihub-terms-conditions"
  }, {
    path: "/namavali/durga",
    component: _c3d69618,
    name: "namavali-durga"
  }, {
    path: "/namavali/ganesh",
    component: _df1da6fa,
    name: "namavali-ganesh"
  }, {
    path: "/namavali/hanuman",
    component: _4306e8f3,
    name: "namavali-hanuman"
  }, {
    path: "/namavali/krishna",
    component: _52ee6c7a,
    name: "namavali-krishna"
  }, {
    path: "/namavali/lakshmi",
    component: _7fadb120,
    name: "namavali-lakshmi"
  }, {
    path: "/namavali/ram",
    component: _4b87b637,
    name: "namavali-ram"
  }, {
    path: "/namavali/saraswati",
    component: _3afae5ae,
    name: "namavali-saraswati"
  }, {
    path: "/namavali/shiva",
    component: _7a7a27b8,
    name: "namavali-shiva"
  }, {
    path: "/namavali/swami-samarth",
    component: _5b5cb82e,
    name: "namavali-swami-samarth"
  }, {
    path: "/namavali/vishnu",
    component: _34db5b2c,
    name: "namavali-vishnu"
  }, {
    path: "/quotes/author",
    component: _17937620,
    name: "quotes-author"
  }, {
    path: "/rental-checkout/checkout",
    component: _68b3aebc,
    name: "rental-checkout-checkout"
  }, {
    path: "/rentals/best-car-rental-in-goa",
    component: _6a6ebe9e,
    name: "rentals-best-car-rental-in-goa"
  }, {
    path: "/rentals/car-goa-rent",
    component: _6d5dd7df,
    name: "rentals-car-goa-rent"
  }, {
    path: "/rentals/car-hire-goa-airport",
    component: _307d2ca0,
    name: "rentals-car-hire-goa-airport"
  }, {
    path: "/rentals/car-hire-goa-with-driver",
    component: _84c58c18,
    name: "rentals-car-hire-goa-with-driver"
  }, {
    path: "/rentals/car-hire-in-goa",
    component: _0c90b70e,
    name: "rentals-car-hire-in-goa"
  }, {
    path: "/rentals/car-hire-in-goa-with-driver",
    component: _7afcf0d0,
    name: "rentals-car-hire-in-goa-with-driver"
  }, {
    path: "/rentals/car-rental-dabolim-airport",
    component: _7b25f22d,
    name: "rentals-car-rental-dabolim-airport"
  }, {
    path: "/rentals/car-rental-goa",
    component: _728582bc,
    name: "rentals-car-rental-goa"
  }, {
    path: "/rentals/car-rental-goa-airport",
    component: _4baca220,
    name: "rentals-car-rental-goa-airport"
  }, {
    path: "/rentals/car-rental-in-goa-with-driver",
    component: _c5f34b40,
    name: "rentals-car-rental-in-goa-with-driver"
  }, {
    path: "/rentals/car-rental-in-margao",
    component: _d8835af0,
    name: "rentals-car-rental-in-margao"
  }, {
    path: "/rentals/car-rental-madgaon",
    component: _2c2ee69c,
    name: "rentals-car-rental-madgaon"
  }, {
    path: "/rentals/car-rental-panjim",
    component: _1b006a1e,
    name: "rentals-car-rental-panjim"
  }, {
    path: "/rentals/car-rental-south-goa",
    component: _17295c22,
    name: "rentals-car-rental-south-goa"
  }, {
    path: "/rentals/goa-car-rental-price",
    component: _21153ec6,
    name: "rentals-goa-car-rental-price"
  }, {
    path: "/rentals/goa-car-rental-self-drive",
    component: _7876c97c,
    name: "rentals-goa-car-rental-self-drive"
  }, {
    path: "/rentals/goa-car-rental-service",
    component: _3b199cb2,
    name: "rentals-goa-car-rental-service"
  }, {
    path: "/rentals/goa-car-rentals",
    component: _22195cff,
    name: "rentals-goa-car-rentals"
  }, {
    path: "/rentals/goa-vehicle-rent",
    component: _41dfef07,
    name: "rentals-goa-vehicle-rent"
  }, {
    path: "/rentals/self-drive-car-hire-goa",
    component: _df5de58c,
    name: "rentals-self-drive-car-hire-goa"
  }, {
    path: "/rentals/self-drive-car-hire-goa-airport",
    component: _27974e90,
    name: "rentals-self-drive-car-hire-goa-airport"
  }, {
    path: "/rentals/self-drive-cars-goa-airport",
    component: _1240280a,
    name: "rentals-self-drive-cars-goa-airport"
  }, {
    path: "/restaurants/index1",
    component: _5f9d41ae,
    name: "restaurants-index1"
  }, {
    path: "/stotra/athrvashirsha",
    component: _677e7525,
    name: "stotra-athrvashirsha"
  }, {
    path: "/stotra/dattamaharaj",
    component: _0c25d7fe,
    name: "stotra-dattamaharaj"
  }, {
    path: "/stotra/ganesh-chalisa",
    component: _0e7fed57,
    name: "stotra-ganesh-chalisa"
  }, {
    path: "/stotra/ganpati",
    component: _4dc4af59,
    name: "stotra-ganpati"
  }, {
    path: "/stotra/gurucharitra",
    component: _32f2c838,
    name: "stotra-gurucharitra"
  }, {
    path: "/stotra/hanuman-chalisa",
    component: _065079da,
    name: "stotra-hanuman-chalisa"
  }, {
    path: "/stotra/krishnashtakam",
    component: _15de0742,
    name: "stotra-krishnashtakam"
  }, {
    path: "/stotra/mahalakshmi",
    component: _96db210e,
    name: "stotra-mahalakshmi"
  }, {
    path: "/stotra/maruti",
    component: _d43c693a,
    name: "stotra-maruti"
  }, {
    path: "/stotra/pranamyashirsha",
    component: _dc62f9ec,
    name: "stotra-pranamyashirsha"
  }, {
    path: "/stotra/ramraksha",
    component: _51e4bd0d,
    name: "stotra-ramraksha"
  }, {
    path: "/stotra/shiv-stuti",
    component: _6b8e37b5,
    name: "stotra-shiv-stuti"
  }, {
    path: "/stotra/shubhang-karoti",
    component: _1671a240,
    name: "stotra-shubhang-karoti"
  }, {
    path: "/stotra/swami-ashtak",
    component: _d486289a,
    name: "stotra-swami-ashtak"
  }, {
    path: "/stotra/tarak-mantra",
    component: _e706bfc4,
    name: "stotra-tarak-mantra"
  }, {
    path: "/stotra/vishnushatanama",
    component: _3fe2d890,
    name: "stotra-vishnushatanama"
  }, {
    path: "/stotra/vyankatesh",
    component: _27f48a3a,
    name: "stotra-vyankatesh"
  }, {
    path: "/taxi/airport-cabs-goa",
    component: _38addbb0,
    name: "taxi-airport-cabs-goa"
  }, {
    path: "/taxi/airport-taxi-booking",
    component: _3222c4f7,
    name: "taxi-airport-taxi-booking"
  }, {
    path: "/taxi/best-cab-service-goa",
    component: _28437e0c,
    name: "taxi-best-cab-service-goa"
  }, {
    path: "/taxi/best-taxi-service-goa",
    component: _19f32980,
    name: "taxi-best-taxi-service-goa"
  }, {
    path: "/taxi/cab-service-goa",
    component: _fa7b56c6,
    name: "taxi-cab-service-goa"
  }, {
    path: "/taxi/cab-service-goa-airport",
    component: _0413909b,
    name: "taxi-cab-service-goa-airport"
  }, {
    path: "/taxi/cab-service-south-goa",
    component: _5b8e2b9d,
    name: "taxi-cab-service-south-goa"
  }, {
    path: "/taxi/cab-to-goa",
    component: _7af0ad9b,
    name: "taxi-cab-to-goa"
  }, {
    path: "/taxi/cabs-goa-airport",
    component: _4b486e64,
    name: "taxi-cabs-goa-airport"
  }, {
    path: "/taxi/cabs-in-goa",
    component: _ef63fe18,
    name: "taxi-cabs-in-goa"
  }, {
    path: "/taxi/cheapest-cab-service-goa",
    component: _8a31ff26,
    name: "taxi-cheapest-cab-service-goa"
  }, {
    path: "/taxi/cheapest-taxi-service-goa",
    component: _2c02586d,
    name: "taxi-cheapest-taxi-service-goa"
  }, {
    path: "/taxi/dabolim-airport-taxi",
    component: _3ff7f992,
    name: "taxi-dabolim-airport-taxi"
  }, {
    path: "/taxi/goa-airport-taxi",
    component: _b205b082,
    name: "taxi-goa-airport-taxi"
  }, {
    path: "/taxi/goa-cabs",
    component: _1383568e,
    name: "taxi-goa-cabs"
  }, {
    path: "/taxi/goa-taxi",
    component: _1e7503fd,
    name: "taxi-goa-taxi"
  }, {
    path: "/taxi/goa-taxi-booking",
    component: _74cb1489,
    name: "taxi-goa-taxi-booking"
  }, {
    path: "/taxi/goa-taxi-rental",
    component: _dd14a6b8,
    name: "taxi-goa-taxi-rental"
  }, {
    path: "/taxi/taxi-goa-airport",
    component: _6aee6d53,
    name: "taxi-taxi-goa-airport"
  }, {
    path: "/taxi/taxi-in-goa",
    component: _9b9baeb6,
    name: "taxi-taxi-in-goa"
  }, {
    path: "/taxi/taxi-services-in-goa",
    component: _6bde737c,
    name: "taxi-taxi-services-in-goa"
  }, {
    path: "/watersports/5-in-1-water-sports-in-goa",
    component: _627892e2,
    name: "watersports-5-in-1-water-sports-in-goa"
  }, {
    path: "/watersports/goa-water-sports-package",
    component: _7358ae54,
    name: "watersports-goa-water-sports-package"
  }, {
    path: "/watersports/water-activities-in-goa",
    component: _5fcedf7f,
    name: "watersports-water-activities-in-goa"
  }, {
    path: "/watersports/water-activity-goa",
    component: _68b2d37a,
    name: "watersports-water-activity-goa"
  }, {
    path: "/watersports/water-rides-in-goa",
    component: _dbc1f22a,
    name: "watersports-water-rides-in-goa"
  }, {
    path: "/watersports/water-sports-5-in-1",
    component: _5207d7fd,
    name: "watersports-water-sports-5-in-1"
  }, {
    path: "/watersports/water-sports-goa",
    component: _369f0df3,
    name: "watersports-water-sports-goa"
  }, {
    path: "/watersports/water-sports-in-goa",
    component: _657c9bad,
    name: "watersports-water-sports-in-goa"
  }, {
    path: "/watersports/water-sports-in-north-goa",
    component: _5dc20225,
    name: "watersports-water-sports-in-north-goa"
  }, {
    path: "/watersports/water-sports-in-south-goa",
    component: _541384ed,
    name: "watersports-water-sports-in-south-goa"
  }, {
    path: "/aarti/namavali/namavali",
    component: _69879001,
    name: "aarti-namavali-namavali"
  }, {
    path: "/aarti/sahitya/sahitya",
    component: _25e80afd,
    name: "aarti-sahitya-sahitya"
  }, {
    path: "/places/category/:slug?",
    component: _70a7fd16,
    name: "places-category-slug"
  }, {
    path: "/restaurants/category/:slug?",
    component: _0f42fb48,
    name: "restaurants-category-slug"
  }, {
    path: "/activities/:slug",
    component: _de6981c4,
    name: "activities-slug"
  }, {
    path: "/blog/:slug",
    component: _496439da,
    name: "blog-slug"
  }, {
    path: "/caption/:slug",
    component: _72ffcb5b,
    name: "caption-slug"
  }, {
    path: "/edit-event/:index",
    component: _5e83d1f6,
    name: "edit-event"
  }, {
    path: "/enquiry-sent/:index",
    component: _f4884f4c,
    name: "enquiry-sent"
  }, {
    path: "/event/:slug?",
    component: _27e4b38f,
    name: "event-slug"
  }, {
    path: "/events/:slug",
    component: _1449dc4a,
    name: "events-slug"
  }, {
    path: "/experiences/:category?",
    component: _0be5a051,
    name: "experiences-category"
  }, {
    path: "/explore/:category",
    component: _431643ca,
    name: "explore-category"
  }, {
    path: "/hotel/:slug?",
    component: _15af6a29,
    name: "hotel-slug"
  }, {
    path: "/instagram-captions/:slug?",
    component: _0c0b4679,
    name: "instagram-captions-slug"
  }, {
    path: "/offers-success/:index",
    component: _3840bf8f,
    name: "offers-success"
  }, {
    path: "/offers/:slug",
    component: _93b5b170,
    name: "offers-slug"
  }, {
    path: "/order-details/:index",
    component: _4d260b56,
    name: "order-details"
  }, {
    path: "/packages/:slug",
    component: _7ad430c4,
    name: "packages-slug"
  }, {
    path: "/places/:slug",
    component: _087b1fbd,
    name: "places-slug"
  }, {
    path: "/podcast/:slug",
    component: _58bd320e,
    name: "podcast-slug"
  }, {
    path: "/quotes/:slug",
    component: _43982f28,
    name: "quotes-slug"
  }, {
    path: "/recipe/:slug?",
    component: _3ce5fb02,
    name: "recipe-slug"
  }, {
    path: "/recipes/:slug",
    component: _e0a9294c,
    name: "recipes-slug"
  }, {
    path: "/rental-order-details/:index",
    component: _defe783c,
    name: "rental-order-details"
  }, {
    path: "/rental-success/:index",
    component: _5ec4b23c,
    name: "rental-success"
  }, {
    path: "/restaurant/:slug?",
    component: _cf892b24,
    name: "restaurant-slug"
  }, {
    path: "/success/:index",
    component: _1a7720d6,
    name: "success"
  }, {
    path: "/tags/:index",
    component: _d437577a,
    name: "tags"
  }, {
    path: "/taxi-order-details/:index",
    component: _a2688eb0,
    name: "taxi-order-details"
  }, {
    path: "/taxi-success/:index",
    component: _6c592828,
    name: "taxi-success"
  }, {
    path: "/tourism/:slug",
    component: _a89de340,
    name: "tourism-slug"
  }, {
    path: "/tours/:slug",
    component: _9e7c9660,
    name: "tours-slug"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index"
  }, {
    path: "/:state",
    component: _8c4e7e6a,
    name: "state"
  }, {
    path: "/:state/activities",
    component: _ebf5ac72,
    name: "state-activities"
  }, {
    path: "/:state/beaches",
    component: _4f64f6e6,
    name: "state-beaches"
  }, {
    path: "/:state/city",
    component: _cb1fa5f6,
    name: "state-city"
  }, {
    path: "/:state/events",
    component: _24999f73,
    name: "state-events"
  }, {
    path: "/:state/events-today",
    component: _55b580b2,
    name: "state-events-today"
  }, {
    path: "/:state/events-tomorrow",
    component: _35ebfd75,
    name: "state-events-tomorrow"
  }, {
    path: "/:state/events-weekend",
    component: _73afe68d,
    name: "state-events-weekend"
  }, {
    path: "/:state/farms",
    component: _f5de610e,
    name: "state-farms"
  }, {
    path: "/:state/forts",
    component: _150d5bc4,
    name: "state-forts"
  }, {
    path: "/:state/hostels",
    component: _4775b180,
    name: "state-hostels"
  }, {
    path: "/:state/hotels",
    component: _1fb487ce,
    name: "state-hotels"
  }, {
    path: "/:state/nightlife",
    component: _0cf23e50,
    name: "state-nightlife"
  }, {
    path: "/:state/packages",
    component: _ddb692f2,
    name: "state-packages"
  }, {
    path: "/:state/picnic-spots",
    component: _ed1f7588,
    name: "state-picnic-spots"
  }, {
    path: "/:state/places",
    component: _18cae2e6,
    name: "state-places"
  }, {
    path: "/:state/religious-places",
    component: _7e2c9978,
    name: "state-religious-places"
  }, {
    path: "/:state/restaurants",
    component: _ad32f55c,
    name: "state-restaurants"
  }, {
    path: "/:state/villages",
    component: _0c49ab3e,
    name: "state-villages"
  }, {
    path: "/:state/waterfalls",
    component: _3e06467b,
    name: "state-waterfalls"
  }, {
    path: "/:state/packages/category",
    component: _1b8a89c8,
    name: "state-packages-category"
  }, {
    path: "/:state/activities/category/:slug?",
    component: _03d14e94,
    name: "state-activities-category-slug"
  }, {
    path: "/:state/packages/category/:slug",
    component: _1ef7cc58,
    name: "state-packages-category-slug"
  }, {
    path: "/:state/restaurants/category/:slug?",
    component: _60a6bf69,
    name: "state-restaurants-category-slug"
  }, {
    path: "/:state/activities/:city",
    component: _836c9afc,
    name: "state-activities-city"
  }, {
    path: "/:state/city/:city",
    component: _14e8cf40,
    name: "state-city-city"
  }, {
    path: "/:state/events/:category",
    component: _e68a0a04,
    name: "state-events-category"
  }, {
    path: "/:state/hotels/:city",
    component: _4bf3bf54,
    name: "state-hotels-city"
  }, {
    path: "/:state/places/:city",
    component: _2e01d861,
    name: "state-places-city"
  }, {
    path: "/:state/restaurants/:city",
    component: _be060c66,
    name: "state-restaurants-city"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
