export const BannerSliderItem = () => import('../../components/BannerSliderItem.vue' /* webpackChunkName: "components/banner-slider-item" */).then(c => wrapFunctional(c.default || c))
export const Dragscroll = () => import('../../components/Dragscroll.vue' /* webpackChunkName: "components/dragscroll" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/EventCard.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const EventCardGeneral = () => import('../../components/EventCardGeneral.vue' /* webpackChunkName: "components/event-card-general" */).then(c => wrapFunctional(c.default || c))
export const EventCardPortrait = () => import('../../components/EventCardPortrait.vue' /* webpackChunkName: "components/event-card-portrait" */).then(c => wrapFunctional(c.default || c))
export const ExploreCard = () => import('../../components/ExploreCard.vue' /* webpackChunkName: "components/explore-card" */).then(c => wrapFunctional(c.default || c))
export const FadeSliderBg = () => import('../../components/FadeSliderBg.vue' /* webpackChunkName: "components/fade-slider-bg" */).then(c => wrapFunctional(c.default || c))
export const FaqCard = () => import('../../components/FaqCard.vue' /* webpackChunkName: "components/faq-card" */).then(c => wrapFunctional(c.default || c))
export const FaqCardPost = () => import('../../components/FaqCardPost.vue' /* webpackChunkName: "components/faq-card-post" */).then(c => wrapFunctional(c.default || c))
export const FeaturedCategoryCard = () => import('../../components/FeaturedCategoryCard.vue' /* webpackChunkName: "components/featured-category-card" */).then(c => wrapFunctional(c.default || c))
export const FilterCard = () => import('../../components/FilterCard.vue' /* webpackChunkName: "components/filter-card" */).then(c => wrapFunctional(c.default || c))
export const HelpCard = () => import('../../components/HelpCard.vue' /* webpackChunkName: "components/help-card" */).then(c => wrapFunctional(c.default || c))
export const HotelCard = () => import('../../components/HotelCard.vue' /* webpackChunkName: "components/hotel-card" */).then(c => wrapFunctional(c.default || c))
export const MainCategoryCard = () => import('../../components/MainCategoryCard.vue' /* webpackChunkName: "components/main-category-card" */).then(c => wrapFunctional(c.default || c))
export const PackageCard = () => import('../../components/PackageCard.vue' /* webpackChunkName: "components/package-card" */).then(c => wrapFunctional(c.default || c))
export const PackageForm = () => import('../../components/PackageForm.vue' /* webpackChunkName: "components/package-form" */).then(c => wrapFunctional(c.default || c))
export const PackageTrendingCard = () => import('../../components/PackageTrendingCard.vue' /* webpackChunkName: "components/package-trending-card" */).then(c => wrapFunctional(c.default || c))
export const PlaceCard = () => import('../../components/PlaceCard.vue' /* webpackChunkName: "components/place-card" */).then(c => wrapFunctional(c.default || c))
export const PlaceCardv2 = () => import('../../components/PlaceCardv2.vue' /* webpackChunkName: "components/place-cardv2" */).then(c => wrapFunctional(c.default || c))
export const PlaceCardv2Restaurant = () => import('../../components/PlaceCardv2Restaurant.vue' /* webpackChunkName: "components/place-cardv2-restaurant" */).then(c => wrapFunctional(c.default || c))
export const PopularCategoryCard = () => import('../../components/PopularCategoryCard.vue' /* webpackChunkName: "components/popular-category-card" */).then(c => wrapFunctional(c.default || c))
export const PremiumCoupon = () => import('../../components/PremiumCoupon.vue' /* webpackChunkName: "components/premium-coupon" */).then(c => wrapFunctional(c.default || c))
export const RecipeCard = () => import('../../components/RecipeCard.vue' /* webpackChunkName: "components/recipe-card" */).then(c => wrapFunctional(c.default || c))
export const Recognition = () => import('../../components/Recognition.vue' /* webpackChunkName: "components/recognition" */).then(c => wrapFunctional(c.default || c))
export const RentalCard = () => import('../../components/RentalCard.vue' /* webpackChunkName: "components/rental-card" */).then(c => wrapFunctional(c.default || c))
export const RentalCardV2 = () => import('../../components/RentalCardV2.vue' /* webpackChunkName: "components/rental-card-v2" */).then(c => wrapFunctional(c.default || c))
export const RentalCardV3 = () => import('../../components/RentalCardV3.vue' /* webpackChunkName: "components/rental-card-v3" */).then(c => wrapFunctional(c.default || c))
export const RentalDetail = () => import('../../components/RentalDetail.vue' /* webpackChunkName: "components/rental-detail" */).then(c => wrapFunctional(c.default || c))
export const RentalSection = () => import('../../components/RentalSection.vue' /* webpackChunkName: "components/rental-section" */).then(c => wrapFunctional(c.default || c))
export const RestaurantCard = () => import('../../components/RestaurantCard.vue' /* webpackChunkName: "components/restaurant-card" */).then(c => wrapFunctional(c.default || c))
export const SearchCards = () => import('../../components/SearchCards.vue' /* webpackChunkName: "components/search-cards" */).then(c => wrapFunctional(c.default || c))
export const SectionLoader = () => import('../../components/SectionLoader.vue' /* webpackChunkName: "components/section-loader" */).then(c => wrapFunctional(c.default || c))
export const Share = () => import('../../components/Share.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c))
export const ShimmerDetail = () => import('../../components/ShimmerDetail.vue' /* webpackChunkName: "components/shimmer-detail" */).then(c => wrapFunctional(c.default || c))
export const ShimmerDetailV2 = () => import('../../components/ShimmerDetailV2.vue' /* webpackChunkName: "components/shimmer-detail-v2" */).then(c => wrapFunctional(c.default || c))
export const ShimmerEffect = () => import('../../components/ShimmerEffect.vue' /* webpackChunkName: "components/shimmer-effect" */).then(c => wrapFunctional(c.default || c))
export const ShimmerEffectMultiple = () => import('../../components/ShimmerEffectMultiple.vue' /* webpackChunkName: "components/shimmer-effect-multiple" */).then(c => wrapFunctional(c.default || c))
export const SocialHead = () => import('../../components/SocialHead.vue' /* webpackChunkName: "components/social-head" */).then(c => wrapFunctional(c.default || c))
export const StayCard = () => import('../../components/StayCard.vue' /* webpackChunkName: "components/stay-card" */).then(c => wrapFunctional(c.default || c))
export const TaxiCard = () => import('../../components/TaxiCard.vue' /* webpackChunkName: "components/taxi-card" */).then(c => wrapFunctional(c.default || c))
export const TicketCard = () => import('../../components/TicketCard.vue' /* webpackChunkName: "components/ticket-card" */).then(c => wrapFunctional(c.default || c))
export const Toc = () => import('../../components/Toc.vue' /* webpackChunkName: "components/toc" */).then(c => wrapFunctional(c.default || c))
export const TocList = () => import('../../components/TocList.vue' /* webpackChunkName: "components/toc-list" */).then(c => wrapFunctional(c.default || c))
export const TrendingCard = () => import('../../components/TrendingCard.vue' /* webpackChunkName: "components/trending-card" */).then(c => wrapFunctional(c.default || c))
export const TrendingCard2 = () => import('../../components/TrendingCard2.vue' /* webpackChunkName: "components/trending-card2" */).then(c => wrapFunctional(c.default || c))
export const GAdUnitCode = () => import('../../components/gAdUnitCode.vue' /* webpackChunkName: "components/g-ad-unit-code" */).then(c => wrapFunctional(c.default || c))
export const LoaderOld = () => import('../../components/loader-old.vue' /* webpackChunkName: "components/loader-old" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const LoaderComponent = () => import('../../components/loaderComponent.vue' /* webpackChunkName: "components/loader-component" */).then(c => wrapFunctional(c.default || c))
export const LoaderComponentCopy = () => import('../../components/loaderComponentCopy.vue' /* webpackChunkName: "components/loader-component-copy" */).then(c => wrapFunctional(c.default || c))
export const TableLoading = () => import('../../components/tableLoading.vue' /* webpackChunkName: "components/table-loading" */).then(c => wrapFunctional(c.default || c))
export const ActivityBlog = () => import('../../components/ActivityBlog/ActivityBlog.vue' /* webpackChunkName: "components/activity-blog" */).then(c => wrapFunctional(c.default || c))
export const ActivityBlog2 = () => import('../../components/ActivityBlog/ActivityBlog2.vue' /* webpackChunkName: "components/activity-blog2" */).then(c => wrapFunctional(c.default || c))
export const CategoryCard = () => import('../../components/CategorySection/CategoryCard.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryFilter = () => import('../../components/CategorySection/CategoryFilter.vue' /* webpackChunkName: "components/category-filter" */).then(c => wrapFunctional(c.default || c))
export const CategorySlick = () => import('../../components/CategorySection/CategorySlick.vue' /* webpackChunkName: "components/category-slick" */).then(c => wrapFunctional(c.default || c))
export const Coupon = () => import('../../components/CouponSection/Coupon.vue' /* webpackChunkName: "components/coupon" */).then(c => wrapFunctional(c.default || c))
export const CouponExclusive = () => import('../../components/CouponSection/CouponExclusive.vue' /* webpackChunkName: "components/coupon-exclusive" */).then(c => wrapFunctional(c.default || c))
export const CouponSlick = () => import('../../components/CouponSection/CouponSlick.vue' /* webpackChunkName: "components/coupon-slick" */).then(c => wrapFunctional(c.default || c))
export const CouponSlickActivity = () => import('../../components/CouponSection/CouponSlickActivity.vue' /* webpackChunkName: "components/coupon-slick-activity" */).then(c => wrapFunctional(c.default || c))
export const ReviewCard = () => import('../../components/ReviewSection/ReviewCard.vue' /* webpackChunkName: "components/review-card" */).then(c => wrapFunctional(c.default || c))
export const ReviewCard2 = () => import('../../components/ReviewSection/ReviewCard2.vue' /* webpackChunkName: "components/review-card2" */).then(c => wrapFunctional(c.default || c))
export const ReviewSlick = () => import('../../components/ReviewSection/ReviewSlick.vue' /* webpackChunkName: "components/review-slick" */).then(c => wrapFunctional(c.default || c))
export const SuccessRentalSuccessBox = () => import('../../components/Success/RentalSuccessBox.vue' /* webpackChunkName: "components/success-rental-success-box" */).then(c => wrapFunctional(c.default || c))
export const SuccessBox = () => import('../../components/Success/SuccessBox.vue' /* webpackChunkName: "components/success-box" */).then(c => wrapFunctional(c.default || c))
export const TimelineItem = () => import('../../components/Timeline/Timeline-item.vue' /* webpackChunkName: "components/timeline-item" */).then(c => wrapFunctional(c.default || c))
export const TimelineItem2 = () => import('../../components/Timeline/Timeline-item2.vue' /* webpackChunkName: "components/timeline-item2" */).then(c => wrapFunctional(c.default || c))
export const Timeline = () => import('../../components/Timeline/Timeline.vue' /* webpackChunkName: "components/timeline" */).then(c => wrapFunctional(c.default || c))
export const TimelineItemTwo = () => import('../../components/Timeline/TimelineItemTwo.vue' /* webpackChunkName: "components/timeline-item-two" */).then(c => wrapFunctional(c.default || c))
export const TrendingSlick = () => import('../../components/Trending/TrendingSlick.vue' /* webpackChunkName: "components/trending-slick" */).then(c => wrapFunctional(c.default || c))
export const CoreAnimationOverlay = () => import('../../components/core/AnimationOverlay.vue' /* webpackChunkName: "components/core-animation-overlay" */).then(c => wrapFunctional(c.default || c))
export const CoreBackHeader = () => import('../../components/core/BackHeader.vue' /* webpackChunkName: "components/core-back-header" */).then(c => wrapFunctional(c.default || c))
export const CoreBlogDetailHeader = () => import('../../components/core/BlogDetailHeader.vue' /* webpackChunkName: "components/core-blog-detail-header" */).then(c => wrapFunctional(c.default || c))
export const CoreCurrentLoactionHeader = () => import('../../components/core/CurrentLoactionHeader.vue' /* webpackChunkName: "components/core-current-loaction-header" */).then(c => wrapFunctional(c.default || c))
export const CoreFooter = () => import('../../components/core/Footer.vue' /* webpackChunkName: "components/core-footer" */).then(c => wrapFunctional(c.default || c))
export const CoreHeader = () => import('../../components/core/Header.vue' /* webpackChunkName: "components/core-header" */).then(c => wrapFunctional(c.default || c))
export const CoreHeaderAbout = () => import('../../components/core/HeaderAbout.vue' /* webpackChunkName: "components/core-header-about" */).then(c => wrapFunctional(c.default || c))
export const CoreImageHeader = () => import('../../components/core/ImageHeader.vue' /* webpackChunkName: "components/core-image-header" */).then(c => wrapFunctional(c.default || c))
export const CoreInterlinks = () => import('../../components/core/Interlinks.vue' /* webpackChunkName: "components/core-interlinks" */).then(c => wrapFunctional(c.default || c))
export const CoreNavigationHeader = () => import('../../components/core/NavigationHeader.vue' /* webpackChunkName: "components/core-navigation-header" */).then(c => wrapFunctional(c.default || c))
export const CorePageDetailHeader = () => import('../../components/core/PageDetailHeader.vue' /* webpackChunkName: "components/core-page-detail-header" */).then(c => wrapFunctional(c.default || c))
export const CorePageHeader = () => import('../../components/core/PageHeader.vue' /* webpackChunkName: "components/core-page-header" */).then(c => wrapFunctional(c.default || c))
export const CoreRentalHeader = () => import('../../components/core/RentalHeader.vue' /* webpackChunkName: "components/core-rental-header" */).then(c => wrapFunctional(c.default || c))
export const CustomerContentMyRentals = () => import('../../components/customer-content/MyRentals.vue' /* webpackChunkName: "components/customer-content-my-rentals" */).then(c => wrapFunctional(c.default || c))
export const CustomerContentMyTaxis = () => import('../../components/customer-content/MyTaxis.vue' /* webpackChunkName: "components/customer-content-my-taxis" */).then(c => wrapFunctional(c.default || c))
export const CustomerContentNoContent = () => import('../../components/customer-content/NoContent.vue' /* webpackChunkName: "components/customer-content-no-content" */).then(c => wrapFunctional(c.default || c))
export const CustomerContentOrderdetailsbox = () => import('../../components/customer-content/orderdetailsbox.vue' /* webpackChunkName: "components/customer-content-orderdetailsbox" */).then(c => wrapFunctional(c.default || c))
export const DynamicPageSectionsCityPage = () => import('../../components/dynamicPageSections/cityPage.vue' /* webpackChunkName: "components/dynamic-page-sections-city-page" */).then(c => wrapFunctional(c.default || c))
export const GalleryInstagramGallery = () => import('../../components/gallery/InstagramGallery.vue' /* webpackChunkName: "components/gallery-instagram-gallery" */).then(c => wrapFunctional(c.default || c))
export const GalleryInstagramGalleryAbout = () => import('../../components/gallery/InstagramGalleryAbout.vue' /* webpackChunkName: "components/gallery-instagram-gallery-about" */).then(c => wrapFunctional(c.default || c))
export const HowItWorks = () => import('../../components/how-it-works/HowItWorks.vue' /* webpackChunkName: "components/how-it-works" */).then(c => wrapFunctional(c.default || c))
export const ModalsCheckoutModal = () => import('../../components/modals/CheckoutModal.vue' /* webpackChunkName: "components/modals-checkout-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRentalTnCModal = () => import('../../components/modals/RentalTnCModal.vue' /* webpackChunkName: "components/modals-rental-tn-c-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsReviewModal = () => import('../../components/modals/ReviewModal.vue' /* webpackChunkName: "components/modals-review-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSocialSharing = () => import('../../components/modals/SocialSharing.vue' /* webpackChunkName: "components/modals-social-sharing" */).then(c => wrapFunctional(c.default || c))
export const ModalsVehicleModal = () => import('../../components/modals/VehicleModal.vue' /* webpackChunkName: "components/modals-vehicle-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsLocationModal = () => import('../../components/modals/locationModal.vue' /* webpackChunkName: "components/modals-location-modal" */).then(c => wrapFunctional(c.default || c))
export const TaxiVehicleCard = () => import('../../components/taxi/VehicleCard.vue' /* webpackChunkName: "components/taxi-vehicle-card" */).then(c => wrapFunctional(c.default || c))
export const CoreSideBarMenu = () => import('../../components/core/SideBar/SideBarMenu.vue' /* webpackChunkName: "components/core-side-bar-menu" */).then(c => wrapFunctional(c.default || c))
export const TaxiCoreFooter = () => import('../../components/taxi/core/Footer.vue' /* webpackChunkName: "components/taxi-core-footer" */).then(c => wrapFunctional(c.default || c))
export const TaxiCoreHeader = () => import('../../components/taxi/core/Header.vue' /* webpackChunkName: "components/taxi-core-header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
